import { KnownError } from "@/network/Http";
import Logger from "@fimagine/logger";

export const log_error = (e: any) => {
  if (!(e instanceof Error)) {
    Logger.Info.print('log_error', e);
    return;
  }

  const err_cls_name = Object.getPrototypeOf(e).name;
  Logger.Info.print(err_cls_name,
    e.message,
    "\nstack:\n",
    e.stack,
    "\ncause:\n",
    e.cause
  );
  if (KnownError.is(e) && (e.cause as any)?.stack) {
    Logger.Info.print(err_cls_name, (e.cause as any)?.stack)
  }

};
