import { ApiHttp } from "./network/ApiHttp";
import { Compat } from "./network/Compat";
import { KnownError } from "./network/Http";
import { GlobalStore } from "./store/GlobalStore";

ApiHttp.addRequestInterceptor((url, opts) => {
  if (!API_BASE) return [url, opts];
  if (!url.startsWith('http')) {
    const apiBase = API_BASE.endsWith('/') ? API_BASE : API_BASE + '/';
    const apiTail = url.startsWith('/') ? url.substring(1) : url
    url = apiBase + apiTail
  } else {
    const { protocol, host } = window.location
    const lc = `${protocol}//${host}/`
    if (url.startsWith(lc)) {
      const apiBase = API_BASE.endsWith('/') ? API_BASE : API_BASE + '/';
      url = url.replace(lc, apiBase);
    }
  }
  if (url.startsWith(API_BASE)) {
    const headers = opts.headers || {}
    Compat.Header.set(headers, "Authorization", GlobalStore.value.session_id)
    Compat.Header.set(headers, "Content-Type", "application/json;charset=UTF-8")
    opts.headers = headers;
  }
  return [url, opts]
})

ApiHttp.addErrorInterceptor((e: any) => {
  if (KnownError.is(e) && e.http_status === 401) {
    GlobalStore.dispatch({ type: 'logout', payload: null })
  }
  return e
})