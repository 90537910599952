import { useNavigate } from "react-router-dom";
import { GlobalStore } from "../store/GlobalStore";
import { useEffect } from "react";
import React from "react";
import { RouteLoading } from "./RouteLoading";

export function RouteGuard(props: any) {
  const { auth = true, children } = props;
  const [{ session_id }] = GlobalStore.useStore();
  const navigate = useNavigate();
  useEffect(() => {
    if (!session_id && auth) navigate('/', { replace: true });
  }, [navigate, session_id, auth]);
  return (
    <React.Suspense fallback={<RouteLoading />}>
      {children}
    </React.Suspense>
  );
}
