import { ApiHttp } from "@/network/ApiHttp";
import { GlobalStore } from "@/store/GlobalStore";
import { useEffect } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import './App.css';
import { Paths } from './Paths';
import './init';
import { Toast } from "./component/Toast";
import { log_error } from "./pages/Main/log_error";
import { KnownError } from "./network/Http";
const router = createBrowserRouter(Paths.Routes)

function App() {
  const [{ session_id }, dispatch] = GlobalStore.useStore()
  const [state] = GlobalStore.useStore()
  useEffect(() => {
    if (!session_id) return
    const c = new AbortController()
    ApiHttp.post<any, any>(`user/info`, void 0, void 0, { signal: c.signal })
      .then(r => dispatch({
        type: 'user_info', payload: {
          admin: r.data.admin,
          user_id: r.data.id,
          username: r.data.username
        }
      }))
      .catch(ApiHttp.ignoreAbort)
      .catch(e => {
        KnownError.is(e)
      })
      .catch(log_error)
    return () => c.abort()
  }, [dispatch, session_id])


  return (
    <Toast.Provider>
      <div className="App">
        <RouterProvider router={router} future={{ v7_startTransition: true }} />
      </div>
    </Toast.Provider>
  );
}

export default App;
