import React, { useMemo } from "react";
import { SizeType } from "../SizeEnum";
import styles from "./style.module.less"
import classNames from "classnames";
export interface BadgeProps extends React.HTMLAttributes<HTMLSpanElement> {
  label?: React.ReactNode;
  size?: SizeType;
}
export function Badge(props: BadgeProps) {
  const { label, children = label, size = 's', className, ..._p } = props;
  const _class_name = useMemo(() => classNames(styles.badge, styles[`badge_size_${size}`], className), [])
  return <span {..._p} className={_class_name}>{children}</span>
}