import _localforage from "localforage";
import { Initer, makeStore, Reducer } from "../utils/makeStore";
import { IDraftItem } from "@/pages/BlogEditor/draftsMgr";

let _forage: LocalForage | undefined;
const getForage = async () => {
  const username = await _localforage.getItem<string>('username')
  const forage_name = 'drafts_' + username;
  if (_forage?.config().name === forage_name) return _forage;

  return _forage = _localforage.createInstance({
    driver: _localforage.INDEXEDDB,
    storeName: 'drafts_settings',
    name: forage_name
  })
}
const defaultValue = {
  draft_visible: false,
  last_draft_id: void 0 as string | undefined,
  loading: false,
  saving: false,
  list: [] as IDraftItem[]
}
export type Value = typeof defaultValue
export interface TypePayloadMap {
  "last_draft_id": string | undefined,
  "draft_visible": boolean,
  "set_loading": boolean,
  "set_saving": boolean,
  "set_list": IDraftItem[],
}
export type Actions =
  { type: "last_draft_id", payload: TypePayloadMap["last_draft_id"] } |
  { type: "draft_visible", payload: TypePayloadMap["draft_visible"] } |
  { type: "set_loading", payload: TypePayloadMap["set_loading"] } |
  { type: "set_saving", payload: TypePayloadMap["set_saving"] } |
  { type: "set_list", payload: TypePayloadMap["set_list"] }

const reducer: Reducer<Value, Actions> = async (state, { type, payload }) => {
  switch (type) {
    case "last_draft_id": {
      const forage = await getForage()
      forage.setItem('last_draft_id', payload)
      return { ...state, last_draft_id: payload }
    }
    case "draft_visible": {
      const forage = await getForage()
      forage.setItem('draft_visible', payload)
      return { ...state, draft_visible: payload }
    }
    case "set_loading": return { ...state, loading: payload }
    case "set_saving": return { ...state, saving: payload }
    case "set_list": return { ...state, list: payload }
  }
  return state
}
const initer: Initer<Value> = async () => {
  const forage = await getForage()
  return {
    last_draft_id: await forage.getItem<string>('last_draft_id').catch(e => void 0).then(v => v || void 0),
    draft_visible: await forage.getItem<boolean>('draft_visible').catch(e => void 0).then(v => v || false)
  }
}
export const DraftsStore = makeStore<Value, Actions>("DraftsStore", defaultValue, reducer, initer);