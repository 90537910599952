import React from 'react';
import { RouteObject } from 'react-router-dom';
import { RouteGuard } from './component/RouteGuard';
export namespace Paths {
  export enum All {
    Root = "",
    Main = "/",
    Edtior = "/edtior",
    About = "/about",
    ArticleList = "/article_list",
    Article = "/article/:article_id",
    Chatroom = "/chatroom",
    Coding = "/coding",
    Firework = "/firework",
    ChineseChess = "/chinese_chess",
    Chess = "/chess"
  }
  export const Components: Record<All, React.ComponentType | undefined> = {
    [All.Root]: undefined,
    [All.Main]: React.lazy(() => import('@/pages/Main')),
    [All.Edtior]: React.lazy(() => import('@/pages/BlogEditor')),
    [All.Article]: React.lazy(() => import('@/component/ArticleView')),
    [All.ArticleList]: React.lazy(() => import('@/pages/Main/ArticaleListView')),
    [All.Coding]: React.lazy(() => import('@/pages/CodeEditor')),
    [All.Chatroom]: React.lazy(() => import('@/pages/Chatroom')),
    [All.About]: React.lazy(() => import('@/pages/About')),
    [All.Firework]: React.lazy(() => import('@/pages/Firework')),
    [All.ChineseChess]: React.lazy(() => import('@/pages/ChineseChess')),
    [All.Chess]: React.lazy(() => import('@/pages/Chess'))
  }
  export const Indexs: Partial<Record<All, All>> = {
    [All.Main]: All.ArticleList,
  }
  export const Relations: Partial<Record<All, All[]>> = {
    [All.Root]: [All.Main, All.Edtior, All.Coding, All.Chatroom],
    [All.Main]: [All.Article, All.About, All.Firework, All.ChineseChess, All.Chess],
  }
  export const Guardeds: Partial<Record<All, boolean>> = {
    [All.Edtior]: true,
  }
  export const Routes: RouteObject[] = buildRoutes(All.Root)!

  function buildRoute(path: All, index: boolean = false): RouteObject {
    const C = Components[path] ?? (() => <>Component of "{path}" not found!</>);
    const guarded = Guardeds[path] === true;
    const element = <RouteGuard auth={guarded} ><C /></RouteGuard>
    const children = buildRoutes(path)
    const ret: RouteObject = index ? { index, element } : { path, element }
    if (children?.length) ret.children = children;
    return ret
  }
  function buildRoutes(parent: All): RouteObject[] | undefined {
    const index_path = Indexs[parent] ?? false;
    const children_path = Relations[parent] ?? [];
    if (index_path === false && !children_path?.length)
      return void 0

    const ret: RouteObject[] = children_path.map(path => buildRoute(path))
    if (index_path !== false) ret.unshift(buildRoute(index_path, true))

    return ret;
  }
}
