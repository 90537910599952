import localforage from "localforage";
import { Initer, makeStore, Reducer } from "../utils/makeStore";
const defaultValue = {
  sesame: false,
  session_id: '',
  user_id: 0,
  username: '',
  admin: 0,
  site_name: '',
  site_breif: '',
  is_me: false,
  sts: {
    accessKeyId: '',
    accessKeySecret: '',
    expiration: '',
    securityToken: ''
  }
}
export type Value = typeof defaultValue
export interface TypePayloadMap {
  "sesame": boolean,
  "logout": null,
  "login": Pick<Value, 'session_id' | 'username' | 'admin'>,
  "user_info": Pick<Value, 'username' | 'admin' | 'user_id'>,
  "site_info": Pick<Value, 'site_name' | 'site_breif' | 'is_me'>,
  "sts_info": Value['sts'],
}
export type Actions =
  { type: "sesame", payload: TypePayloadMap["sesame"] } |
  { type: "logout", payload: TypePayloadMap["logout"] } |
  { type: "login", payload: TypePayloadMap["login"] } |
  { type: "user_info", payload: TypePayloadMap["user_info"] } |
  { type: "site_info", payload: TypePayloadMap["site_info"] } |
  { type: "sts_info", payload: TypePayloadMap["sts_info"] }

const reducer: Reducer<Value, Actions> = async (state, { type, payload }) => {
  switch (type) {
    case 'sesame':
      await localforage.setItem('sesame', payload)
      return { ...state, sesame: payload }
    case "logout":
      await localforage.removeItem('session_id')
      await localforage.removeItem('username')
      await localforage.removeItem('admin')
      await localforage.removeItem('user_id')
      const { site_breif, site_name, sesame } = state;
      return { ...defaultValue, site_breif, site_name, sesame }
    case "login":
      await localforage.setItem('session_id', payload.session_id)
      return { ...state, ...payload }
    case "user_info":
      await localforage.setItem('username', payload.username)
      await localforage.setItem('admin', payload.admin)
      await localforage.setItem('user_id', payload.user_id)
      return { ...state, ...payload }
    case "site_info":
      await localforage.setItem('site_name', payload.site_name)
      await localforage.setItem('site_breif', payload.site_breif)
      return { ...state, ...payload }
    case "sts_info":
      await localforage.setItem('sts', payload)
      return { ...state, sts: payload }
  }
  return state
}
const initer: Initer<Value> = async () => {
  const ret = {
    sesame: await localforage.getItem<boolean>('sesame')
      .catch(_ => void 0).then(v => v ?? defaultValue.sesame),
    session_id: await localforage.getItem<string>('session_id')
      .catch(_ => void 0).then(v => v ?? defaultValue.session_id),
    username: await localforage.getItem<string>('username').catch(e => void 0).then(v => v ?? ''),
    user_id: await localforage.getItem<number>('user_id').catch(e => void 0).then(v => v ?? 0),
    admin: await localforage.getItem<number>('admin').catch(e => void 0).then(v => v ?? 0),
    site_name: await localforage.getItem<string>('site_name')
      .catch(_ => void 0).then(v => v || defaultValue.site_name),
    site_breif: await localforage.getItem<string>('site_breif')
      .catch(_ => void 0).then(v => v || defaultValue.site_breif),
    sts: await localforage.getItem<Value['sts']>('sts')
      .catch(_ => void 0).then(r => r || defaultValue.sts),
  }
  // console.log('initer', ret)
  return ret;
}
export const GlobalStore = makeStore<Value, Actions>("GlobalStore", defaultValue, reducer, initer);