import { SizeType } from "@/component/SizeEnum";
import _classnames from "classnames";
import _styles from "./style.module.css";
export interface SpaceProps extends React.HTMLAttributes<HTMLDivElement> {
  align?: 'center' | 'left' | 'right';
  vertical?: boolean;
  horizontal?: boolean;
  styles?: { item: React.CSSProperties }
  classnames?: { item: string }
  size?: SizeType;
  _ref?: React.Ref<HTMLDivElement>;
}
export function Space(props: SpaceProps) {
  const { align = 'center', className, children, styles, classnames, vertical, size = 'm', horizontal, _ref, ..._p } = props;
  const _is_v = vertical === true && horizontal !== true;

  const _root_classname = _classnames(
    _styles.space,
    _styles[`space_align_${align}`],
    _styles[`space_size_${size}`],
    className
  );

  const _item_classname = _classnames({
    [_styles?.space_item_v]: _is_v,
    [_styles?.space_item_h]: !_is_v,
  }, classnames?.item);

  const render_item = (child: React.ReactNode, idx: number) => {
    if (null === child) return null;
    if (void 0 === child) return null;
    return (
      <div
        key={idx}
        className={_item_classname}
        style={styles?.item}>
        {child}
      </div>
    )
  }

  return (
    <div {..._p} ref={_ref} className={_root_classname}>
      {
        (null === children || void 0 === children) ? null :
          Array.isArray(children) ?
            children.map(render_item) :
            render_item(children, 0)
      }
    </div>
  );
}
