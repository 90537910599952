import React, { PropsWithChildren, createContext, useCallback, useContext, useEffect, useReducer, useState } from "react";

export type AnyAction = {
  type: string;
  payload?: any;
}
export interface IStore<Value, Actions> {
  readonly Provider: (props: PropsWithChildren) => JSX.Element;
  readonly useStore: () => readonly [Value, React.Dispatch<Actions>];
  readonly value: Value;
  readonly dispatch: React.Dispatch<Actions>
}
class Store<Value, Actions> implements IStore<Value, Actions> {
  readonly name: string;
  get Provider() { return this._Provider! }
  get useStore() { return this._useStore! }
  get value() { return this._value! }
  set value(v) { this._value = v }
  get dispatch() { return this._dispatch! }
  set dispatch(v) { this._dispatch = v }
  private _Provider?: (props: PropsWithChildren) => JSX.Element;
  private _useStore?: () => readonly [Value, React.Dispatch<Actions>];
  private _value?: Value;
  private _dispatch?: React.Dispatch<Actions>
  constructor(name: string) {
    this.name = name
  }
  init(
    Provider: (props: PropsWithChildren) => JSX.Element,
    useStore: () => readonly [Value, React.Dispatch<Actions>],
    value: Value,
  ): this {
    this._Provider = Provider;
    this._useStore = useStore;
    this._value = value;
    return this;
  }

  
}
export interface Reducer<Value, Actions> {
  (state: Value, actions: Actions): Value | Promise<Value>
}
export interface Initer<Value> {
  (): Promise<Partial<Value>> | Partial<Value>
}
export interface Dispatch<Actions> {
  (actions: Actions): void
}
export function makeStore<
  Value extends NonNullable<{}> = NonNullable<{}>,
  Actions extends AnyAction = AnyAction
>(
  name: string,
  defaultValue: Value,
  reducer: Reducer<Value, Actions>,
  initer?: Initer<Value>
): IStore<Value, Actions> {
  let store = new Store<Value, Actions>(name);
  let _status: "loaded" | "loading" | "idle" = initer ? "idle" : "loaded";
  let _jobs: (() => Promise<void> | void)[] = []
  let _job_id = 1;
  const get_job_id = () => name + (_job_id++);
  function run_job() {
    const job = _jobs[0]
    if (!job) return;
    Promise.resolve(job());
  }

  const context = createContext<[Value, Dispatch<Actions>]>([defaultValue, async () => { }]);
  const _reducer = (state = defaultValue, action: Actions) => {
    switch (action.type) {
      case '__merge':
        store.value = { ...state, ...action.payload };
        break;
      case '__reset':
        store.value = { ...action.payload };
        break;
      default: console.warn("_reducer fall through!", action)
    }
    _jobs.shift();
    run_job();
    return store.value
  };
  interface IProviderProps extends PropsWithChildren {
    showAnyway?: boolean;
  }
  function Provider(props: IProviderProps): JSX.Element {
    const { children, showAnyway = false } = props;
    const [state, _dispatch] = useReducer(_reducer, defaultValue);
    const [status, setStatus] = useState(_status)

    const dispatch = useCallback((actions: Actions) => {
      const jid = get_job_id()
      const job = async () => {
        const payload = await reducer(store.value, actions)
        _dispatch({ type: '__reset', payload, jid } as any)
      };
      _jobs.push(job);
      if (_jobs.length === 1) run_job();
    }, [_dispatch])
    store.dispatch = dispatch;

    useEffect(() => {
      if (status !== "idle") return;
      _status = "loading";
      setStatus("loading")
      if (!initer) {
        _status = "loaded";
        setStatus("loaded")
      } else {
        const r = initer?.()
        if (r instanceof Promise) {
          r.then((payload) => {
            _dispatch({ type: '__merge', payload } as any)
          }).finally(() => {
            _status = "loaded";
            setStatus("loaded")
          })
        } else {
          _dispatch({ type: '__merge', r } as any)
          _status = "loaded";
          setStatus("loaded")
        }
      }
    }, [status])
    if (status !== 'loaded' && !showAnyway) return <></>
    return (
      <context.Provider value={[state, dispatch]}>
        {children}
      </context.Provider>
    );
  }
  const useStore = () => useContext(context)
  return store.init(Provider, useStore, defaultValue);
}

// export interface IStoresProps extends PropsWithChildren {
//   stores: (IStore<any, any>)[]
// }
// export function Stores(props: IStoresProps) {
//   const { stores, children } = props
//   if (!stores.length) return <>{children}</>

//   let ret = React.createElement(stores[0].Provider, {}, children)
//   for (let i = 1; i <= stores.length - 1; ++i) {
//     const { Provider } = stores[i];
//     ret = React.createElement(Provider, {}, ret)
//   }
//   return ret
// }